import { z } from "zod"

export const LayerType = {
	landerz: "landerz",
	off_market: "off_market",
	fbso: "fsbo",
	external: "external",
	co_listing: "co_listing",
	transaction: "transaction",
} as const

export const LayerTypes = Object.values(LayerType)

export const DevelopmentType = {
	residential: "Résidentiel",
	mixed: "Mixte",
	commercial: "Commercial",
	subdivision: "Lotissement",
	unidentified: "Inconnu",
	agricultural: "Agricole",
	industrial: "Industriel",
	institutional: "Institutionnel",
} as const

export enum DevelopmentPotential {
	High = "High",
	Medium = "Medium",
	Low = "Low",
	None = "None",
	Unspecified = "Unspecified",
}

export const DevelopmentTypes = Object.values(DevelopmentType)

export const SalesProcessStatus = {
	open: "open",
	accepted_psa: "accepted_psa",
}
/** The Zod schema used to validate a listing */
export const ListingSchema = z.object({
	/// the listing id
	id: z.number(),
	hubspot_record_id: z.string(),
	type: z.nativeEnum(LayerType),
	typeDev: z.string(),
	typeDevArray: z.array(z.nativeEnum(DevelopmentType)),
	constructible: z.nullable(z.number()),
	superficy: z.number(),
	longitude: z.number(),
	latitude: z.number(),
	price: z.nullable(z.number()),
	pricePerSqft: z.nullable(z.number()),
	pricePerConstructibleSqft: z.nullable(z.number()),
	link: z.nullable(z.string()),
	address: z.string(),
	city: z.string(),
	state: z.string(),
	postal_code: z.string(),
	image: z.nullable(z.string()),
	off_market_radius: z.number(),
	sales_process_status: z.nativeEnum(SalesProcessStatus),
	launch_date: z.date().optional(),
	launch_date_ms: z.number().optional(),
	call_for_bids: z.boolean(),
	broker_email: z.string(),
	broker_first_name: z.string(),
	broker_last_name: z.string(),
	broker_phone_number: z.string(),
	broker_picture_url: z.string(),
})

/** A listing is the primary thing our app displays. It represents lots
 * suitable for redevelopment on the market (or likely to be, ex: off_market),
 * either through Landerz brokerage activities or another broker */
export interface Listing extends z.infer<typeof ListingSchema> {}

/** The Zod schema used to validate a parcel (GraphQL version) */
export const ParcelSchema = z.object({
	parcelID: z.string(),
	location: z.object({
		coordinates: z.object({
			longitude: z.string(),
			latitude: z.string(),
		}),
	}),
})

export interface Parcel extends z.infer<typeof ParcelSchema> {}

/** The Zod schema used to validate a prospect (GraphQL version) */
export const ProspectSchema = z.object({
	prospectID: z.string(),
	potential: z.nativeEnum(DevelopmentPotential),
})

export interface Prospect extends z.infer<typeof ProspectSchema> {}

/** The Zod schema used to validate a transaction */
export const TransactionSchema = z.object({
	id: z.string(),
	location: z.object({
		coordinates: z.object({
			longitude: z.string().optional(),
			latitude: z.string().optional(),
		}),
		address: z.string().nullish(),
		parcelNumbers: z.array(z.string()).optional(),
		area: z.number().optional(),
	}),
	deal: z.object({
		price: z.number().optional(),
		registryDate: z.date().optional().optional(),
		seller: z.object({
			name: z.string().optional(),
			neq: z.string().optional(),
		}),
		buyer: z.object({
			name: z.string().optional(),
			neq: z.string().optional(),
		}),
	}),
	registrationNumber: z.string().optional(),
})

export interface Transaction extends z.infer<typeof TransactionSchema> {}
