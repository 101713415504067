<template>
	<Card class="transaction" :item="transaction" :previewed="previewed" :selected="selected">
		<header>
			<h3>{{ $t("transaction.registrationId") }}: #{{ transaction.id }}</h3>
			<input type="checkbox" @change.stop="event => handleAddToExportList(event, transaction)" @click.stop />
		</header>

		<dl>
			<div>
				<dt>address</dt>
				<dd>{{ transaction.location.address }}</dd>
			</div>
			<div>
				<dt>{{ $t("transaction.parcels") }}</dt>
				<dd>{{ transaction.location.parcelNumbers }}</dd>
			</div>
			<div>
				<dt>{{ $t("transaction.area") }}</dt>
				<dd>{{ transaction.location.area }}</dd>
			</div>
			<div>
				<dt>{{ $t("transaction.price") }}</dt>
				<dd>{{ transaction.deal.price }}</dd>
			</div>
			<div>
				<dt>{{ $t("transaction.registryDate") }}</dt>
				<dd>{{ transaction.deal.registryDate.toLocaleDateString("en-CA") }}</dd>
			</div>
			<div>
				<dt>{{ $t("transaction.registrationNumber") }}</dt>
				<dd>{{ transaction.registrationNumber }}</dd>
			</div>
			<div>
				<dt>{{ $t("transaction.sellerName") }}</dt>
				<dd>{{ transaction.deal.seller.name }}</dd>
			</div>
			<div>
				<dt>{{ $t("transaction.sellerNeq") }}</dt>
				<dd>{{ transaction.deal.seller.neq }}</dd>
			</div>
			<div>
				<dt>{{ $t("transaction.buyerName") }}</dt>
				<dd>{{ transaction.deal.buyer.name }}</dd>
			</div>
			<div>
				<dt>{{ $t("transaction.buyerNeq") }}</dt>
				<dd>{{ transaction.deal.buyer.neq }}</dd>
			</div>
		</dl>
	</Card>
</template>

<script setup>
import { UseRootStore } from "@/model/RootStore"
import Card from "@/components/Card.vue"

const props = defineProps({
	transaction: {
		type: Object,
		default: () => ({ id: 1, name: "nom" }),
	},
	selected: {
		type: Boolean,
		default: false,
	},
	previewed: {
		type: Boolean,
		default: false,
	},
})

const store = UseRootStore()

function handleAddToExportList(event, transaction) {
	const box = event.target

	// prettier-ignore
	box.checked ?
		store.transactionExportList.add(transaction) :
		store.transactionExportList.delete(transaction)
}
</script>

<style scoped>
.transaction {
	display: flex;
	flex-direction: column;
	padding: 20px;
	box-sizing: border-box;

	header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		h3 {
			text-transform: capitalize;
		}
		input[type="checkbox"] {
			transform: scale(2);
			border-color: black;
			opacity: 1;
		}
	}

	dl {
		display: flex;
		flex-direction: column;

		div {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			padding: 5px;
			border-bottom: 1px solid #777;
			line-height: 1.5rem;
			font-size: 1.2rem;

			dt {
				color: #777;
				text-transform: capitalize;
			}
			dd {
				color: #111;
			}
		}
	}
}
</style>
