<template>
	<main>
		<div :class="{ 'list-panel': true, 'list-panel--hidden': !isShowingListPanel }">
			<!-- The top right black button that is used to toggle the right panel -->
			<div class="list-panel__toggler" @click="isShowingListPanel = !isShowingListPanel">
				<div class="list-panel__toggler-text">
					{{ $t("listings.panel-toggler") }}
				</div>
				<div class="list-panel__toggler-icon">
					<img class="list-panel__toggler--dark" src="@/assets/images/menu-right/icon-list-black.svg" alt="" />
					<img class="list-panel__toggler--light" src="@/assets/images/menu-right/icon-list-white.svg" alt="" />
				</div>
			</div>

			<!-- listing details -->
			<right-panel v-model:isOpen="isShowingListingDetailsPanel" :width="'46.1rem'" :height="'80%'" :maxHeight="'94vh'">
				<!-- The selected parcel base info  -->
				<listing-card-detailed :item="listings.find(l => l.id === selectedListingId)" />
			</right-panel>

			<!-- parcel details -->
			<right-panel v-model:isOpen="isShowingParcelDetailsPanel" :width="'46.1rem'" :height="'80%'" :maxHeight="'94vh'">
				<!-- The selected listing details -->
				<parcel-card-detailed v-if="selectedParcel" :item="selectedParcel" />
			</right-panel>

			<!-- transaction details -->
			<right-panel
				v-if="isUserLoggedIn"
				v-model:isOpen="isShowingTransactionDetailsPanel"
				:width="'46.1rem'"
				:height="'80%'"
				:maxHeight="'94vh'"
			>
				<!-- The selected listing details -->
				<TransactionCardDetailed v-if="selectedTransaction" :transaction="selectedTransaction" />
			</right-panel>

			<!-- cards -->
			<right-panel v-model:isOpen="isShowingListPanel" :width="'54rem'" :maxHeight="'94vh'">
				<div class="listings__content">
					<div class="listings__header">
						<h4>{{ $t("listingHeader") }}</h4>
					</div>
					<div class="listings__seperator"></div>
					<div class="export" v-if="isUserLoggedIn && transactionExportList.size > 0">
						<div class="count">({{ transactionExportList.size }}) {{ $t("selected") }}</div>
						<button @click="exportTransactions(transactionExportList)">{{ $t("export") }}</button>
					</div>
					<div class="listings__filter">
						<Switch :value="isOnlyShowingListingsInViewPort" @input="val => (isOnlyShowingListingsInViewPort = val)">
							{{ $t("onlyShowListingsInViewPort") }}
						</Switch>
					</div>
					<div class="listings__items">
						<listing-card
							v-for="feature in listingsToShow"
							:key="feature.id"
							:item="feature"
							:selected="selectedListingId && feature.id === selectedListingId"
							@mouseover="featureHover(feature.id)"
							@mouseleave="featureHover(null)"
							@click="select(feature.id)"
						/>
						<TransactionCard
							v-if="isUserLoggedIn"
							v-for="transaction in transactions"
							:key="transaction.id"
							:transaction="transaction"
							:selected="selectedTransaction && transaction.id === selectedTransaction.id"
							:previewed="previewedTransaction && transaction.id === previewedTransaction.id"
							@mouseenter="handlePreviewedTransaction(transaction)"
							@mouseleave="handlePreviewedTransaction(null)"
							@click="handleSelectedTransaction(transaction)"
						>
						</TransactionCard>
						<div v-if="!listingsToShow.length" class="list-panel__no-features">
							{{ $t("noListingsAreVisibleWarning") }}
						</div>
					</div>
				</div>
			</right-panel>
		</div>
	</main>
</template>

<script setup>
import Switch from "@/components/Switch.vue"
import ListingCard from "@/components/menu-right/ListingCard.vue"
import ListingCardDetailed from "@/components/menu-right/ListingCardDetailed.vue"
import ParcelCardDetailed from "@/components/menu-right/ParcelCardDetailed.vue"
import TransactionCardDetailed from "@/components/menu-right/TransactionCardDetailed.vue"
import RightPanel from "@/components/menu-right/RightPanel.vue"
import TransactionCard from "@/components/menu-right/TransactionCard.vue"
import * as download from "@/utils/download"
import { UseRootStore } from "@/model/RootStore"
import { storeToRefs } from "pinia"
import { computed, ref, watch, toRaw } from "vue"
import Papa from "papaparse"
import { flatten } from "flat"

const store = UseRootStore()
const {
	listings,
	listingsFiltered,
	listingsFilteredWithViewport,
	hoveredListingId,
	selectedListingId,
	selectedParcelID,
	selectedParcel,
	transactions,
	selectedTransaction,
	previewedTransaction,
	transactionExportList,
	isUserLoggedIn,
} = storeToRefs(store)

const isOnlyShowingListingsInViewPort = ref(true)
const isShowingListPanel = ref(true)
const isShowingListingDetailsPanel = ref(false)
const isShowingParcelDetailsPanel = ref(false)
const isShowingTransactionDetailsPanel = ref(false)

const listingsToShow = computed(() => {
	return isOnlyShowingListingsInViewPort.value ? listingsFilteredWithViewport.value : listingsFiltered.value
})

function featureHover(id) {
	hoveredListingId.value = id
}
function select(id) {
	selectedListingId.value = id
}

function handlePreviewedTransaction(transaction) {
	previewedTransaction.value = transaction || null
}

function handleSelectedTransaction(transaction) {
	store.updateSelectedTransaction(transaction)
}

function exportTransactions(transactions) {
	try {
		const data = Array.from(toRaw(transactions)).map(tr => flatten(tr, { safe: true }))
		const csv = Papa.unparse(data)
		const blob = new Blob([csv], { type: "text/csv" })

		download.push(blob, "transactions.csv")
	} catch (error) {
		window.alert(`CSV download failed\n\n${error}`)
	}
}

watch(selectedListingId, newState => {
	const isListingSelected = Boolean(newState)

	isShowingListingDetailsPanel.value = isListingSelected
	if (isListingSelected) {
		isShowingListPanel.value = true
		isShowingTransactionDetailsPanel.value = false
	}
})
watch(selectedParcelID, newState => {
	const isParcelSelected = Boolean(newState)

	isShowingParcelDetailsPanel.value = isParcelSelected
	isShowingListPanel.value = !isParcelSelected
	isShowingTransactionDetailsPanel.value = !isParcelSelected
	if (selectedListingId.value) {
		isShowingListingDetailsPanel.value = !isParcelSelected
	}
})
watch(selectedTransaction, newState => {
	const isTransactionSelected = Boolean(newState)

	// prettier-ignore
	if (isTransactionSelected) {
		isShowingTransactionDetailsPanel.value = true
		isShowingListPanel.value               = false
		isShowingListingDetailsPanel.value     = false
		isShowingParcelDetailsPanel.value      = false
	} else {
		isShowingTransactionDetailsPanel.value = false
	}
})
</script>

<style lang="scss" scoped>
.list-panel {
	position: absolute;
	top: 4.2rem;
	right: 1.2rem;
	display: flex;
	gap: 0.6rem;
	max-height: calc(100% - 6rem);
	pointer-events: none;
	transition:
		right ease-out 0.2s,
		gap ease-out 0.2s;
	&--hidden {
		gap: 0;
		right: 0rem;
	}
	&__toggler {
		pointer-events: auto;
		position: absolute;
		top: 1rem;
		right: 2rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		padding: 1.2rem 1.4rem;
		color: #fff;
		background-color: #191716;
		align-items: center;
		cursor: pointer;
		border: 0.2rem solid #191716;
		border-radius: 5rem;
		transition: all 0.2s ease-out;
		&-text {
			font-family: "basier_circlemedium", sans-serif;
			font-size: 1.4rem;
			font-weight: 500;
			white-space: nowrap;
			padding-top: 2px;
		}
		&-icon {
			position: relative;
			width: 1.6rem;
			height: 1.6rem;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				transition: opacity ease-out 0.2s;
			}
		}
		&--light {
			opacity: 1;
		}
		&:hover {
			color: #191716;
			background-color: #fff;
			.list-panel__toggler--light {
				opacity: 0;
			}
			.list-panel__toggler--dark {
				opacity: 1;
			}
		}
	}
	.right-panel {
		pointer-events: auto;
	}
	.listings {
		&__content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			min-width: 40rem;
			height: 100%;
			gap: 2.05rem;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 1.8rem 1.4rem 2.4rem 1.4rem;
		}
		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.6rem;
			h4 {
				font-size: 1.8rem;
				font-family: "basier_circlemedium", sans-serif;
				font-weight: 500;
				color: #191716;
			}
		}
		&__seperator {
			width: 100%;
			height: 0.15rem;
			background-color: #c4c4c4;
		}
		&__filter {
			.list-item {
				display: flex;
				justify-content: space-between;
				min-width: 49.2rem;
			}
			.list-label {
				font-family: "basier_circleregular", sans-serif;
				font-size: 1.4rem;
				font-weight: 400;
			}
		}
		&__items {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: 10px;
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
	&__no-features {
		line-height: 2;
		padding-top: 0.5rem;
	}
}

.export {
	display: flex;
	gap: 20px;
	font-size: 1.5em;
	align-items: baseline;

	button {
		order: -1;
		padding: 0.5em 2em;
		background-color: black;
		color: white;
		font-weight: bold;
		border-radius: 5px;
		cursor: pointer;
		box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.24);

		&:active {
			box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.24);
			transform: translateX(1px) translateY(1px);
		}
	}
}
</style>
