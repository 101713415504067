<template>
	<div class="listings-filters">
		<div class="section">
			<h5>{{ $t("filters.dev-type-title") }}</h5>
			<div class="content">
				<toggle-list v-model="activeLayers" :items="layersItems" :is-icon-active="true" />
			</div>
		</div>
		<div class="seperator"></div>

		<div class="section">
			<h5>{{ $t("filters.others-title") }}</h5>
			<div class="content filters-range">
				<range-filter
					v-for="(filterConfig, id) in listingRangeFiltersConfig"
					:key="id"
					v-model="listingRangeFilters[id]"
					:min="filterConfig.min"
					:max="filterConfig.max"
					:step="filterConfig.step"
				>
					<template #label>
						{{ $t("listings." + id) }}
					</template>
				</range-filter>

				<div v-if="isUserLoggedIn" class="range-filter">
					<div class="range-filter__label">{{ $t("filters.data-range") }}</div>
					<div class="date-range">
						<div class="lower bound">
							<label for="min-date">{{ $t("listings.label_min") }}</label>
							<input type="date" id="min-date" v-model="minDate" />
						</div>
						<div class="upper bound">
							<label for="max-date">{{ $t("listings.label_max") }}</label>
							<input type="date" id="max-date" v-model="maxDate" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="seperator"></div>

		<div class="section">
			<h5>{{ $t("listings.typeDev") }}</h5>

			<div class="content filters-type-dev">
				<vue-select
					v-model="devTypeSelected"
					multiple
					:options="devTypeItems"
					:placeholder="$t('listings.all-dev-types')"
				>
				</vue-select>
			</div>
		</div>
	</div>
</template>

<script>
import { config } from "@/AppConfig.ts"
import RangeFilter from "@/components/menu-left/filtering/RangeFilter.vue"
import ToggleList from "@/components/menu-left/filtering/ToggleList.vue"
import { UseRootStore } from "@/model/RootStore"
import { storeToRefs } from "pinia"
import { computed, reactive, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import VueSelect from "vue-select"
import "vue-select/dist/vue-select.css"

const { superficy, constructible, price, development_type: devtypeConfig } = config.filtersDefault

const listingRangeFiltersConfig = {
	superficy,
	constructible,
	price,
}

export default {
	components: {
		ToggleList,
		RangeFilter,
		VueSelect,
	},
	emits: ["update-filters"],
	setup(_, { emit }) {
		const { t } = useI18n()
		const store = UseRootStore()
		const { filters: storeFilters, isUserLoggedIn } = storeToRefs(store)
		const dateRange = storeFilters.value.rangeFilters.date

		// format a date in YYYY-MM-DD format
		function dateToExtendedISO8601(date) {
			const y = date.getFullYear()
			const m = String(date.getMonth() + 1).padStart(2, "0")
			const d = String(date.getDate()).padStart(2, "0")
			return `${y}-${m}-${d}`
		}
		// convert a YYYY-MM-DD string to a Date
		function extendedISO8601ToDate(date) {
			const [y, m, d] = date.split("-").map(Number)
			return new Date(y, m - 1, d)
		}

		const minDate = ref(dateToExtendedISO8601(dateRange.min))
		const maxDate = ref(dateToExtendedISO8601(dateRange.max))

		let listingTypeConfig = config.filters
		if (isUserLoggedIn.value) {
			listingTypeConfig = listingTypeConfig.concat(config.restrictedFilters)
		}

		const layersItems = computed(() =>
			listingTypeConfig.map(id => ({
				id,
				label: t(`listings-layers.${id}`),
			}))
		)
		const devTypeItems = computed(() =>
			devtypeConfig.map(id => ({
				id,
				label: t(`dev-types.${id}`),
			}))
		)
		const listingRangeFilters = reactive({ ...listingRangeFiltersConfig })

		const activeLayers = ref(listingTypeConfig)
		const devTypeSelected = ref([])
		const updatedFilters = computed(() => {
			return {
				layers: activeLayers.value,
				rangeFilters: listingRangeFilters,
				devTypes: devTypeSelected.value.length ? devTypeSelected.value.map(d => d.id) : devtypeConfig,
			}
		})

		watch(activeLayers, layers => {
			storeFilters.value.layers = layers
		})

		watch(
			listingRangeFilters,
			filters => {
				storeFilters.value.rangeFilters.price = filters.price
				storeFilters.value.rangeFilters.superficy = filters.superficy
				storeFilters.value.rangeFilters.constructible = filters.constructible
			},
			{ deep: true }
		)

		watch(devTypeSelected, devType => {
			storeFilters.value.devTypes = devType.length ? devType.map(d => d.id) : devtypeConfig
		})

		watch(minDate, min => {
			storeFilters.value.rangeFilters.date = {
				min: extendedISO8601ToDate(min),
				max: storeFilters.value.rangeFilters.date.max,
			}
		})
		watch(maxDate, max => {
			storeFilters.value.rangeFilters.date = {
				max: extendedISO8601ToDate(max),
				min: storeFilters.value.rangeFilters.date.min,
			}
		})

		return {
			activeLayers,
			layersItems,
			devTypeItems,
			listingRangeFiltersConfig,
			listingRangeFilters,
			devTypeSelected,
			isUserLoggedIn,
			minDate,
			maxDate,
		}
	},
}
</script>

<style>
.listings-filters {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow-y: auto;
	overflow-x: hidden;
	padding-top: 22px;

	.seperator {
		width: 100%;
		height: 1.5px;
		background-color: #c4c4c4;
		margin-bottom: 8px;
	}
	.section {
		display: flex;
		flex-direction: column;
		gap: 15px;
		h5 {
			font-family: "basier_circlesemibold", sans-serif;
			font-size: 14px;
			font-weight: 600;
			line-height: 18px;
		}
	}
	.content {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding-left: 12px;
		padding-right: 4px;
	}
	.filters-range {
		padding: 0px;
	}

	.date-range {
		--input-width: 125px;

		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 1em;

		.bound {
			width: var(--input-width);

			.lower {
				text-transform: capitalize;
			}
			.upper {
			}
		}

		label {
			font-size: 1.2rem;
			padding-bottom: 8px;
			font-style: italic;

			&::after {
				content: ": ";
			}
		}
		input[type="date"] {
			display: flex;
			justify-content: flex-end;
			padding: 4px 8px;
			width: var(--input-width);
			border: 1px solid #ddd;

			&:focus {
				border: 2px solid #0074d9;
				outline: none;
			}
		}
	}

	.filters-type-dev {
		min-height: 50px;
		font-size: 1.5rem;
		width: 32rem;
		position: relative;

		ul,
		li {
			position: relative;
		}
	}
}
</style>
