import i18n, { updatePageMetaInfo } from "@/lang/i18n"
import { DevelopmentTypes, LayerTypes, Listing, Transaction } from "@/model/DataModel.ts"
import { defineStore } from "pinia"
import { reactive } from "vue"
import { useLocalStorage } from "@vueuse/core"
import { config } from "@/AppConfig.ts"

export interface MapboxCameraPosition {
	zoom: number
	centerLatitude: number
	centerLongitude: number
	bearing?: number
	pitch?: number
}

type MapboxCameraPositionHash =
	| `${number}/${number}/${number}`
	| `${number}/${number}/${number}/${number}`
	| `${number}/${number}/${number}/${number}/${number}`

export const UseRootStore = defineStore("root-store", {
	state: () => ({
		cameraPosition: useLocalStorage<MapboxCameraPosition>("cameraPosition", {} as MapboxCameraPosition),

		userApiToken: useLocalStorage("userApiToken", "") as string,

		/// the active filters for the listings displayed in the right panel
		filters: reactive({
			// by default show the listing of all types [landerz, off_market, ...]
			layers: reactive(LayerTypes),

			/// by default show the listings of all development types [Résidentiel, Commercial, ...]
			devTypes: reactive(DevelopmentTypes),

			rangeFilters: reactive({
				superficy: { min: 0, max: 100000000, step: 1000 },
				constructible: { min: 0, max: 5000000, step: 1000 },
				price: { min: 0, max: 50000000, step: 1000 },
				date: {
					min: new Date("2010-01-01T00:00:00"),
					max: new Date("2030-01-01T00:00:00"),
				},
			}),
		}),

		// All transactions
		transactions: [] as Transaction[],

		// The currently selected transaction
		selectedTransaction: null as null | Transaction,

		// The currently previewed transaction (e.g. hovered)
		previewedTransaction: null as null | Transaction,

		// The list of transactions that have been selected for export
		transactionExportList: new Set(),

		/// all the listings that we received from the Landerz API
		listings: [] as Listing[],

		/// the listings that are currently visible in the map viewport
		listingsInMapViewPort: [] as Listing[],

		/// the id of the listing that is currently hovered in the right menu
		hoveredListingId: null as null | number,

		/// the id of the listing that is currently selected in the right menu
		selectedListingId: null as null | number,

		// the id of the parcel that is currently selected (by clicking on the cadastre)
		selectedParcelID: null as null | number,

		// the object reprsenting the selected parcel (by clicking on the cadastre)
		selectedParcel: null as null | object,

		// Private state for currently displayed layers.
		// It should be read from using visibleLayers and written to
		// using setVisibleLayers.
		_visibleLayers: ["listings", "cadastre", "prospects"],

		layers: [],
	}),
	getters: {
		getTransactionById: state => {
			return (id: number): Transaction | null => state.transactions.find(t => t.id === id) || null
		},

		// Actual displayed layers.
		// Only returns restricted layers if the user is logged in.
		visibleLayers: state => {
			return state["_visibleLayers"].filter(layer => state.isUserLoggedIn || !config.restrictedLayers.includes(layer))
		},

		listingsSorted: state => [...state.listings].sort((a, b) => (a.id >= b.id ? 1 : -1)),

		listingsFiltered: state => {
			// start the listings already filtered by the map viewport
			let listingsFiltered = [...state.listings]

			// filter the listings by the layers
			listingsFiltered = listingsFiltered.filter(listing => {
				return state.filters.layers.includes(listing.type)
			})

			// filter the listings by the development type
			listingsFiltered = listingsFiltered.filter(listing => {
				if (listing.typeDev === undefined || listing.typeDev === "") {
					return false
				}

				for (const devType of state.filters.devTypes.values()) {
					if (listing.typeDev.includes(devType)) {
						return true
					}
				}
			})

			// filter the listings by superficy, if applicable
			const superficyFilter = state.filters.rangeFilters.superficy
			if (superficyFilter !== null) {
				listingsFiltered = listingsFiltered.filter(listing => {
					if (listing.superficy === undefined || listing.superficy === null || listing.superficy === "null") {
						return false
					}
					const result = listing.superficy >= superficyFilter.min && listing.superficy <= superficyFilter.max
					if (result === false) {
						console.debug(`filtered listing.superficy: ${listing.superficy} and listing.id: ${listing.id}`)
					}
					return result
				})
			}

			// filter the listings by constructible, if applicable
			const constructibleFilter = state.filters.rangeFilters.constructible
			if (constructibleFilter !== null) {
				listingsFiltered = listingsFiltered.filter(listing => {
					if (listing.constructible === undefined || listing.constructible === null) {
						return false
					}
					const result =
						listing.constructible >= constructibleFilter.min && listing.constructible <= constructibleFilter.max
					if (result === false) {
						console.debug(`filtered listing.constructible: ${listing.constructible} and listing.id: ${listing.id}`)
					}
					return result
				})
			}

			// filter the listings by price, if applicable
			const priceFilter = state.filters.rangeFilters.price
			if (priceFilter !== null) {
				listingsFiltered = listingsFiltered.filter(listing => {
					if (listing.price === undefined || listing.price === null) {
						return false
					}
					const result = listing.price >= priceFilter.min && listing.price <= priceFilter.max
					if (result === false) {
						console.debug(`filtered listing.price: ${listing.price} and listing.id: ${listing.id}`)
					}
					return result
				})
			}

			// sort and return the final result
			return listingsFiltered.sort((a, b) => (a.id >= b.id ? 1 : -1))
		},

		listingsFilteredWithViewport: state => {
			// start the listings already filtered by the map viewport
			const filteredListings = [...state.listingsInMapViewPort]

			// sort and return the final result
			return filteredListings.sort((a, b) => (a.id >= b.id ? 1 : -1))
		},

		isUserLoggedIn: state => {
			return Boolean(state.userApiToken)
		},
	},
	actions: {
		updateSelectedTransaction(transaction) {
			this.selectedTransaction?.id === transaction?.id
				? (this.selectedTransaction = null)
				: (this.selectedTransaction = this.getTransactionById(transaction?.id))
		},

		// Replace list of currently displayed layers.
		setVisibleLayers(layers) {
			this["_visibleLayers"] = layers
		},

		updateLanguage(lang) {
			// update all internationalized text strings within app (using t() in
			// templates)
			i18n.global.locale.value = lang

			// update meta information in <head> since some of it is also
			// internationalized
			updatePageMetaInfo(i18n.global.t("meta-title"), i18n.global.t("meta-description"))

			// keep user's preference across sessions
			window.localStorage.setItem("lang", lang)

			// remove lang params if it exists as it's currently only used for SEO
			const url = new URL(window.location)
			if (url.searchParams.has("lang")) {
				// url.searchParams.set('lang', lang)
				url.searchParams.delete("lang")
				window.history.replaceState(null, "", url.toString())
			}
		},

		// Sets the Mapbox [camera
		// position](https://docs.mapbox.com/help/glossary/camera/), as
		// specified in a URL hash (see
		// [option.hash](https://docs.mapbox.com/mapbox-gl-js/api/map))
		//
		// Example:
		//
		//      http://localhost:8080/#12.26/45.49455/-73.60573/-144.6/54
		//
		//      12.26/45.49455/-73.60573/-144.6/54
		//        A      B         C        D   E
		//
		//      A: zoom
		//      B: center latitude
		//      C: center longitude
		//      D: bearing (optional)
		//      E: pitch (optional)
		//
		setCameraPosition(position: MapboxCameraPositionHash): void {
			const [zoom, centerLatitude, centerLongitude, bearing, pitch] = position.split("/")

			this.cameraPosition = {
				zoom,
				centerLatitude,
				centerLongitude,
				bearing,
				pitch,
			} as MapboxCameraPosition
		},

		getCameraPositionHash(): MapboxCameraPositionHash {
			return [
				this.cameraPosition.zoom,
				this.cameraPosition.centerLatitude,
				this.cameraPosition.centerLongitude,
				this.cameraPosition.bearing,
				this.cameraPosition.pitch,
			]
				.filter(Boolean)
				.join("/")
		},

		setLayers(layers) {
			this.layers = layers
		},
	},
})
