<template>
	<div class="search">
		<div class="search__box">
			<i class="fa fa-search search__icon" @click="handleSearchByParcel(inputSearchByParcel)" />
			<input
				v-model="inputSearchByParcel"
				ref="parcelSearchBox"
				type="search"
				class="search__input"
				:placeholder="$t(`search.byParcelPlaceholder`)"
				@keydown.enter="handleSearchByParcel(inputSearchByParcel)"
			/>
		</div>

		<div class="search__box">
			<i class="fa fa-search search__icon" @click="event => handleSearchByAddress(event)" />
			<input
				v-model="inputSearchByAddress"
				type="search"
				class="search__input"
				:placeholder="$t(`search.byAddressPlaceholder`)"
				@click="isSuggestionsActive = true"
				@blur="focusOutHandler"
				@keydown.enter="event => handleSearchByAddress(event)"
			/>
		</div>
		<div v-show="addressSuggestions && isSuggestionsActive" class="search__suggestions">
			<div
				v-for="suggestion in addressSuggestions"
				:key="suggestion.id"
				class="search__suggestion"
				@click="event => handleSearchByAddress(event, suggestion)"
			>
				<slot name="suggestionIcon"></slot>
				{{ suggestion.address.label }}
			</div>
		</div>
	</div>
</template>

<script>
import debounce from "lodash.debounce"
import { ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import * as addressClient from "@/components/map/addressClient"
import { getParcel } from "@/utils/ApiClient"

export default {
	props: {
		mapCenter: {
			type: Object,
			required: true,
		},
	},
	emits: ["search-by-address", "search-by-parcel"],
	setup(props, { emit }) {
		const { t } = useI18n()

		const inputSearchByParcel = ref("")
		const parcelSearchBox = ref(null)

		const inputSearchByAddress = ref("")
		const addressSuggestions = ref([])
		const isSuggestionsActive = ref(true)

		const handleSearchByAddress = (event, suggestion = addressSuggestions.value[0]) => {
			if (!suggestion) return

			isSuggestionsActive.value = false
			inputSearchByAddress.value = suggestion.title
			parcelSearchBox.value.blur()

			const { resultType, position, mapView } = suggestion
			emit("search-by-address", {
				lng: position.lng,
				lat: position.lat,
				bounds: mapView && [
					[mapView.west, mapView.south],
					[mapView.east, mapView.north],
				],
				isRegion: addressClient.isRegion(resultType),
			})
		}

		const handleSearchByParcel = debounce(async searchValue => {
			parcelSearchBox.value.blur()

			const parcelId = searchValue.replace(/\s+/g, "")
			if (!parcelId) {
				return
			}

			const parcel = await getParcel(parcelId)
			if (!parcel) {
				alert(t("search.invalidParcelNumber"))
				return
			}

			const { longitude: lng, latitude: lat } = parcel.location.coordinates
			emit("search-by-parcel", { type: "parcelNumber", lng, lat }, parcelId)
		}, 200)

		const suggestionsHandler = debounce(async () => {
			// reset result set
			addressSuggestions.value = []

			// normalize address input
			const inputAddress = inputSearchByAddress.value.trim()
			if (!Boolean(inputAddress)) {
				return
			}

			// fetch suggestions corresponding to input address
			addressSuggestions.value = await addressClient.getSuggestions(
				inputAddress,
				props.mapCenter,
				10 // max results
			)
		}, 300)

		const focusOutHandler = () => {
			setTimeout(() => {
				isSuggestionsActive.value = false
			}, 200)
		}

		watch(inputSearchByAddress, async () => {
			await suggestionsHandler()
		})

		return {
			handleSearchByAddress,
			handleSearchByParcel,
			inputSearchByAddress,
			inputSearchByParcel,
			addressSuggestions,
			isSuggestionsActive,
			focusOutHandler,
			parcelSearchBox,
		}
	},
}
</script>

<style lang="scss" scoped>
$grey-strong: #888;
$grey-medium: #eee;
$grey-light: #f8f8f8;
$background-light: #fff;
$border-radius: 10px;

.search {
	position: absolute;
	top: 4.35rem;
	right: 9rem;
	left: 50%;
	transform: translate(-50%, -35%);
	width: 35rem;
	font-size: 14px;
	position: absolute;

	&__box {
		display: flex;
		background-color: $background-light;
		border-radius: $border-radius;
		margin-bottom: 5px;
		box-shadow: 0 4px 18px -6px rgba(0, 0, 0, 0.26);

		&:first-child {
			margin-bottom: 5px;
		}
	}
	&__icon {
		display: inline-box;
		width: 4rem;
		font-size: 12px;
		opacity: 0.5;
		cursor: pointer;
		color: #adadad;

		&::before {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
		&:hover {
			opacity: 1;
		}
	}

	&__input {
		background: none;
		position: relative;
		width: 34rem;
		width: 100%;
		height: 4rem;
		font-size: 1.4rem;
		padding: 0rem 2rem 0rem 0rem;
		border: none;
		border-radius: 1rem;
		outline: none;
	}

	&__suggestions {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		background-color: $background-light;
		max-height: 320px;
		box-shadow: 0 14px 18px -6px rgba(0, 0, 0, 0.26);
		border-bottom-right-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
		overflow: auto;
		z-index: 1000;
	}

	&__suggestion {
		display: flex;
		align-items: center;
		column-gap: 12px;
		position: relative;
		text-align: left;
		cursor: pointer;
		padding: 12px 16px;
		font-size: 12px;
		color: $grey-strong;
		&:first-of-type::before {
			content: "";
			position: absolute;
			left: 2%;
			right: 2%;
			top: 0;
			background-color: $grey-medium;
			height: 1px;
		}
		&:not(:last-of-type)::after {
			content: "";
			position: absolute;
			left: 2%;
			right: 2%;
			bottom: 0;
			background-color: $grey-medium;
			height: 1px;
		}
		&:hover {
			background-color: $grey-light;
		}
	}
}
</style>
