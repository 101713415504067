<template>
	<div class="parcel__content">
		<div class="parcel__header">
			<div class="parcel__title-bar">
				<h3 class="parcel__title">
					<span class="parcel__title-label">{{ $t("parcels.parcel") }}</span>
					<!--
						the locale for the lot number is hardcoded to fr, which makes sense for Quebec parcels but could be revisited
					-->
					<span class="parcel__title-number" ref="$parcelNumber">{{
						isNaN(item.parcel_id) ? item.parcel_id : Number.parseInt(item.parcel_id).toLocaleString("fr")
					}}</span>
				</h3>
				<span class="copy-icon" @click="copyParcelNumber">📋</span>
				<transition name="fade">
					<span class="copy-tooltip" v-show="isCopied">copied!</span>
				</transition>
			</div>

			<div class="parcel__sub-title">QC</div>
		</div>

		<div class="parcel__seperator"></div>

		<div class="parcel__items">
			<img v-if="item.image" class="parcel__items-image" loading="lazy" :src="item.image" alt="" />
			<img
				v-else
				class="parcel__items-image"
				loading="lazy"
				src="../../assets/images/menu-right/placeholder-listing-image.png"
				alt=""
			/>

			<div class="parcel__items-details">
				<h4 class="parcel__items-details__header">
					{{ $t("parcels.details") }}
				</h4>

				<div class="parcel__items-details__content">
					<div class="parcel__items-details__detail">
						<span class="parcel__items-details__label">
							{{ $t("parcels.superficy") }}
						</span>
						<span
							v-if="item.area_square_feet"
							:class="{
								'parcel__items-details__value': true,
							}"
						>
							{{ Math.round(item.area_square_feet).toLocaleString() }} {{ $t("listings.superficyShort") }}
						</span>
						<span
							v-if="!item || !item.area_square_feet"
							:class="{
								'parcel__items-details__value': true,
							}"
						>
							{{ $t("parcels.activateCadastre") }}
						</span>
					</div>

					<div class="parcel__items-details__seperator"></div>

					<div class="parcel__items-details__detail">
						<span class="parcel__items-details__label">
							{{ $t("parcels.comingSoon") }}
						</span>
					</div>

					<div class="parcel__items-details__terms">
						<p>{{ $t("terms-parcel-data") }}</p>
					</div>
				</div>
			</div>
		</div>

		<Button
			target="_blank"
			:href="`https://www.google.com/maps/search/?api=1&query=${coords.latitude},${coords.longitude}`"
			>{{ $t("callToActions.openInMaps") }}</Button
		>

		<section class="prospection" v-if="store.isUserLoggedIn">
			<h4>Prospection</h4>
			<div class="dev-potential">
				<label for="dev-potential-levels">{{ $t("development-potential.title") }}</label>
				<vue-select
					id="dev-potential-levels"
					v-model="devPotential"
					:options="devPotentialLevels"
					:clearable="false"
					@option:selected="
						event => {
							updateDevPotential(event.code)
						}
					"
				>
				</vue-select>
			</div>
			<div class="keyboard-shortcuts">
				<!-- TODO translate -->
				<label for="shortcuts"></label>
				<dl id="shortcuts">
					<span
						><dt>q</dt>
						<dd>{{ $t("development-potential.high") }}</dd></span
					>
					<span
						><dt>w</dt>
						<dd>{{ $t("development-potential.medium") }}</dd></span
					>
					<span
						><dt>e</dt>
						<dd>{{ $t("development-potential.low") }}</dd></span
					>
					<span
						><dt>z</dt>
						<dd>{{ $t("development-potential.none") }}</dd></span
					>
					<span
						><dt>x</dt>
						<dd>{{ $t("development-potential.unspecified") }}</dd></span
					>
				</dl>
			</div>
			<footer>
				<button type="button" @click="downloadShapefile">Shapefile</button>
			</footer>
		</section>
	</div>
</template>

<script setup>
import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue"
import { storeToRefs } from "pinia"
import { config } from "@/AppConfig.ts"
import { UseRootStore } from "@/model/RootStore"
import { useProspectStore } from "@/stores/prospectStore.ts"
import { DevelopmentPotential } from "@/model/DataModel.ts"
import { getParcel, getProspect, setProspectPotential, getShapefile } from "@/utils/ApiClient"
import * as download from "@/utils/download"
import { useI18n } from "vue-i18n"
import Button from "@/components/Button.vue"
import VueSelect from "vue-select"
import "vue-select/dist/vue-select.css"

const store = UseRootStore()
const { t } = useI18n()

const prospectStore = useProspectStore()

const props = defineProps({
	item: {
		type: Object,
		require: true,
	},
})

const $parcelNumber = ref()
const isCopied = ref(false)
const coords = ref({})

function currentParcelID() {
	return $parcelNumber.value.innerText.replace(/\s+/g, "")
}

async function downloadShapefile() {
	const token = store.userApiToken
	const parcelID = props.item.parcel_id

	try {
		const [data, filename] = await getShapefile(token, parcelID)

		const blob = new Blob([data], { type: data.type })
		download.push(blob, filename)
	} catch (error) {
		console.error("Shapefile download failed:", error)
	}
}

const devPotential = ref()
const devPotentialLevels = computed(() => [
	{ label: t("development-potential.high"), code: DevelopmentPotential.High },
	{ label: t("development-potential.medium"), code: DevelopmentPotential.Medium },
	{ label: t("development-potential.low"), code: DevelopmentPotential.Low },
	{ label: t("development-potential.none"), code: DevelopmentPotential.None },
	{ label: t("development-potential.unspecified"), code: DevelopmentPotential.Unspecified },
])

function devPotentialCodeToLabel(code) {
	const option = devPotentialLevels.value.find(opt => opt.code === code)
	if (!option) {
		return null
	}
	return option.label
}

async function updateDevPotential(potential) {
	const parcelId = props.item.parcel_id

	const success = await setProspectPotential(store.userApiToken, parcelId, potential)
	if (!success) {
		return success
	}

	setTimeout(() => {
		prospectStore.updateByParcelId(parcelId, { potential })
	}, 1000)

	return true
}

async function handleKeyboardShortcuts(event) {
	if (!store.isUserLoggedIn) {
		return
	}
	if (!store.selectedParcelID) {
		return
	}

	let potential
	switch (event.key) {
		case "q":
			potential = DevelopmentPotential.High
			break
		case "w":
			potential = DevelopmentPotential.Medium
			break
		case "e":
			potential = DevelopmentPotential.Low
			break
		case "z":
			potential = DevelopmentPotential.None
			break
		case "x":
			potential = DevelopmentPotential.Unspecified
			break
	}
	if (!potential) {
		return
	}

	const success = await updateDevPotential(potential)
	if (!success) {
		return
	}

	devPotential.value = devPotentialCodeToLabel(potential)
}

onMounted(() => {
	document.addEventListener("keyup", handleKeyboardShortcuts)
})
onBeforeUnmount(() => {
	document.removeEventListener("keyup", handleKeyboardShortcuts)
})

// on props.item change, fetch the parcel
watch(
	() => props.item,
	async item => {
		const parcelId = item.parcel_id

		const parcel = await getParcel(parcelId)
		if (parcel) {
			coords.value = parcel.location.coordinates
		} else {
			console.error(`No parcel found for ID ${parcelId}`)
		}

		if (store.isUserLoggedIn) {
			const prospect = await getProspect(store.userApiToken, parcelId)
			if (prospect) {
				devPotential.value = devPotentialCodeToLabel(prospect.potential)
			} else {
				devPotential.value = devPotentialCodeToLabel(DevelopmentPotential.Unspecified)
				console.error(`No prospect found for ID ${parcelId}`)
			}
		}
	},
	{ immediate: true }
)

async function copyParcelNumber(event) {
	const parcelID = currentParcelID()

	await navigator.clipboard.writeText(parcelID)
	isCopied.value = true
	setTimeout(() => (isCopied.value = false), 2000)

	// this is just a quick test for the auth token, this data is not yet displayed
	console.info("store.userApiToken", store.userApiToken)
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

h4 {
	color: #191716;
	font-family: "basier_circlesemibold", sans-serif;
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 2.24rem;
}

.prospection {
	label {
		flex: 0 1 40%;
		display: flex;
		align-items: center;
	}

	.dev-potential {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		#dev-potential-levels {
			cursor: pointer;
			flex: 1 1 auto;
		}
	}

	.keyboard-shortcuts {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		#shortcuts {
			flex: 1 1 auto;

			display: flex;
			justify-content: space-between;

			dt {
				display: inline;
				font-weight: bold;
				margin-right: 0.5em;
			}
			dt::after {
				content: ":";
			}
			dd {
				display: inline;
			}
		}
	}

	footer {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;

		button {
			border-radius: 10px;
			padding: 0.5em 1em;
			background-color: var(--accent-color);
			color: white;
			font-weight: bold;
			font-size: 1.2em;
			cursor: pointer;
		}
	}
}

.parcel {
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-width: 44.1rem;
		height: 100%;
		gap: 1.5rem;
		padding: 1.4rem 1.4rem 2.6rem 1.4rem;
	}
	&__header {
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
	}
	&__title-bar {
		display: flex;
		gap: 1.2em;
		align-items: baseline;

		.copy-icon {
			font-size: 2em;
			cursor: pointer;
			user-select: none;
		}
		.copy-tooltip {
			font-size: 1.3em;
			color: var(--accent-color);

			/* added with the <transition name="fade"> element (https://vuejs.org/guide/built-ins/transition) */
			&.fade-leave-active {
				transition: opacity 1s;
				opacity: 0;
			}
		}
	}
	&__title {
		display: flex;
		gap: 0.25em;
		color: #000;
		font-family: "basier_circlemedium", sans-serif;
		font-size: 2.3rem;
		font-weight: 500;
		line-height: 2.53rem;

		&-number::before {
			content: "#";
		}
	}
	&__sub-title {
		color: #777;
		font-family: "basier_circleregular", sans-serif;
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 1.8rem;
	}
	&__seperator {
		width: 100%;
		height: 0.15rem;
		background-color: #c4c4c4;
	}
	&__items {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 2.4rem;
		&-header {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 1.2rem;
			& > div {
				display: flex;
				gap: 1.2rem;
				align-items: center;
			}
			& > div:not(.parcel__items-parcel-type) {
				justify-content: space-between;
			}
		}
		&-image {
			width: 100%;
			height: 23.2rem;
			border-radius: 1.2rem;
			object-fit: cover;
		}
		&-details {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 1rem;
			&__content {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 0.6rem;
			}
			&__detail {
				display: flex;
				justify-content: space-between;
			}
			&__label {
				color: #191716;
				font-family: "basier_circleregular", sans-serif;
				font-size: 1.4rem;
				font-weight: 400;
				line-height: 1.866rem;
			}
			&__value {
				color: #191716;
				font-family: "basier_circlemedium", sans-serif;
				font-size: 1.4rem;
				font-weight: 500;
				line-height: 1.866rem;
				&--green {
					color: #1dbf73;
				}
			}
			&__seperator {
				width: 100%;
				height: 0.05rem;
				background-color: #777;
				&:last-child {
					display: none;
				}
			}
			&__terms {
				padding-top: 2rem;
				font-size: 1.15rem;
			}
		}
	}
}
</style>
